$primary-color: #000000; //#0B0F30
$secondary_g: #e984b1;
$info_g: #59adec;
$danger_g: #d12c47;
$light_g: #e4e7ecc5;
$dark_g: #343a40;
$dark: #495057;
$medium: #343a40;
$light_g: #707070;
$primary_light_g: #363636;
$gray100: #ecf0fa;
$primary-light: #252525e1;
$primary-400: #373737;
$main-bg: #f7f8f9;
$white: #fff;
$gray100: #f3f3f3;
$gray200: #ebebeb;
$gray250: #d7d7d7;
$gray300: #8f8f8f;
$gray700: #999999;
$black: #000;
$black11: #111111;
$black33: #333333;
$black44: #444444;
$black66: #666666;

$white_rgb02: rgba(255, 255, 255, 0.2);
$white_rgb09: rgba(255, 255, 255, 0.9);

//Input
$shadow_input: rgba(92, 92, 92, 0.2);

// Sidebar
$item_hover: rgba(0, 0, 0, 0.35);
$item_text: #ffffff;
$item_icon: rgba(255, 255, 255, 0.9);
$side_top_bg: $primary-color;
$side_bottom_bg: #737373;
$side_thumb: #343435;

//Login
$bg_login: rgba(61, 61, 61, 0.54);